import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CustomAxios from "../../api.service";
import { ENDPOINTS } from "../../constants";
import { useDecodedParams } from "../../utils/param";
import SideNavList from "../side_nav_list";
import Wrapper from "../wrapper";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

function InputGroupWrapper({
  tabsList,
  noTabFilter,
  noCheck = false,
  defaultClosed = false,
}) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [projectDetail, setprojectDetail] = useState({
    project_title: "",
    show_screen: {},
  });
  const [tabCollapsed, setTabCollapsed] = useState(defaultClosed);
  const history = useHistory();
  const { id } = useDecodedParams();
  useEffect(() => {
    if (!noCheck) {
      CustomAxios(ENDPOINTS.getProjectDetail + id, "GET")
        .then((val) => {
          setprojectDetail({
            ...val.data,
            show_screen: { sensitivites: true, ...val.data.show_screen },
          });
        })
        .catch(() => {
          history.push("/");
        });
    }
  }, [id]);
  function handleCollapse(e) {
    e.stopPropagation();
    setTabCollapsed(!tabCollapsed);
  }
  console.log("noTabFilter: ",noTabFilter);
  console.log("tabsList: ",tabsList);
  let filteredTabList = noTabFilter
    ? tabsList
    : tabsList.filter((val) => {
        return val.alwaysShow
          ? true
          : projectDetail.show_screen[val.key] === true;
      });
  console.log("filteredTabList: ",filteredTabList);
  return (
    <Wrapper styles={{}}>
      <div className="project-input-page">
        <SideNavList
          id={id}
          page_id={0}
          tabCollapsed={tabCollapsed}
          tabsList={filteredTabList}
          setSelectedTabIndex={setSelectedTabIndex}
          selectedTabIndex={selectedTabIndex}
          handleCollapse={handleCollapse}
          noCheck={noCheck}
        />
        {projectDetail && (
          <div className="project-right-content">
            {filteredTabList[selectedTabIndex] &&
              React.cloneElement(filteredTabList[selectedTabIndex].component, {
                projectDetail,
              })}
            <div className="bottom-navigation-row">
              {selectedTabIndex !== 0 && (
                <Button
                  onClick={() => setSelectedTabIndex(selectedTabIndex - 1)}
                  type="ghost"
                  icon={<LeftOutlined />}
                >
                  {filteredTabList[selectedTabIndex - 1].name}
                </Button>
              )}
              {selectedTabIndex < filteredTabList.length - 1 && (
                <Button
                  onClick={() => setSelectedTabIndex(selectedTabIndex + 1)}
                  className="next-button"
                  type="ghost"
                  icon={<RightOutlined />}
                >
                  {filteredTabList[selectedTabIndex + 1].name}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
}
InputGroupWrapper.defaultProps = {
  tabsList: [],
  noTabFilter: false,
};
export default InputGroupWrapper;
