import React, { useEffect, useState } from "react";
import arrowBack from "../../assets/images/arrow-back.svg";
import { ReactComponent as Edit } from "../../assets/images/edit.svg";
import { ReactComponent as Recommed } from "../../assets/images/recommend_icon.svg";
import { ReactComponent as Validate } from "../../assets/images/validate_icon.svg";
import { ReactComponent as CarbonFootprint } from "../../assets/images/windmill.svg";
import { ReactComponent as Down } from "../../assets/images/arrow-down.svg";
import { LeftOutlined, RightOutlined,DoubleRightOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
const pages_names=['project_input','validate','recommend']
const pages = [
  <>
    <Edit /> Input 
  </>,
  // <>
  //   <CarbonFootprint /> Carbon Assessment
  // </>,
  <>
    <Validate /> Validate
  </>,
  <>
    <Recommed /> Recommend
  </>
];
function SideNavList({
  tabCollapsed,
  handleCollapse,
  tabsList,
  selectedTabIndex,
  setSelectedTabIndex,
  id,
  showInputNAv = true,
  noCheck,
}) {
  const history = useHistory();
  const [state, setState] = useState(0);
  const menu = (
    <Menu className="side-nav-menu">
      <Menu.Item>
        <Link to={"/project_input/" + id}>{pages[0]}</Link>
      </Menu.Item>
      {/* <Menu.Item>
        <Link to={"/carbon_assessment/" + id}>{pages[1]}</Link>
      </Menu.Item> */}
      <Menu.Item>
        <Link to={"/validate/" + id}>{pages[1]}</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to={"/recommend/" + id}>{pages[2]}</Link>
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    let path = history.location.pathname;
    if (path.startsWith("/project_input")) {
      setState(0);
    } 
    // else if (path.startsWith("/carbon_assessment")) {
    //   setState(1);
    // }
    else if (path.startsWith("/validate")) {
      setState(1);
    } else if (path.startsWith("/recommend")) {
      setState(2);
    }
  }, []);
  return (
    <div
      className={`project-left-navigation ${tabCollapsed ? "collapsed" : ""}`}
    >
      <div className="arrow-back">
        <Link to="/">
          <img src={arrowBack} alt="arrow-back" />
          <span className="back-title">Repository</span>
        </Link>
        <div className="collapse-icon" onClick={handleCollapse}>
          {!tabCollapsed ? <LeftOutlined /> : <RightOutlined />}
        </div>
      </div>
      <div className="side-nav-dropdown-container">
        {!noCheck && showInputNAv && (
          <Dropdown overlay={menu}>
            <Button className="side-nav-dropdown">
              {pages[state]}
              <Down />
            </Button>
          </Dropdown>
        )}
      </div>
      {tabsList.map((val, i) => (
        <div
          key={val.name + i}
          className={`${selectedTabIndex === i ? "active" : ""} right-nav-link`}
          onClick={() => setSelectedTabIndex(i)}
        >
          {val.icon} <span>{val.name}</span>
        </div>
      ))}
       {(tabsList.length>0 && tabsList[0].name!='Saving Simulation' && tabsList[0].name!='Super User Inputs' && tabsList[0].name!='Compare Projects' && state!=2)?
        <Button  style={{width:"100%", fontSize: '13px'}} 
      onClick={() => window.location.href="/"+pages_names[state+1]+"/"+id }>
      <DoubleRightOutlined />
      Next Section: {
        pages_names[state+1][0].toUpperCase() + pages_names[state+1].slice(1) === "Carbon_assessment" ? "Carbon Assessment" : pages_names[state+1][0].toUpperCase() + pages_names[state+1].slice(1)
      }</Button>:""}
    </div>
  );
}
export default SideNavList;
