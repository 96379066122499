/* eslint-disable no-undef */
export const SERVICE_URL = process.env.REACT_APP_API_URL;

/* eslint-enable no-undef */

export const ENDPOINTS = {
  powerbi_page: `${SERVICE_URL}/powerbi-pages`,
  users: `${SERVICE_URL}/users`,
  loginpage: `${SERVICE_URL}/loginpage`,
  listProjects: `${SERVICE_URL}/project/home/listProjects/`,
  getUsersList:`${SERVICE_URL}/project/home/getUsersList/`, 
  grantSuperUserAccess:`${SERVICE_URL}/project/home/grantSuperUserAccess/`,
  revokeSuperUserAccess:`${SERVICE_URL}/project/home/revokeSuperUserAccess/`,
  udpateDateComplete: `${SERVICE_URL}/project/home/udpateDateComplete/`,
  changeVersion: `${SERVICE_URL}/project/home/projectStatus/`,
  projectDropdown: `${SERVICE_URL}/project/home/dropdownMapping/`,
  userInfo: `${SERVICE_URL}/userinfo`,
  logout: `${SERVICE_URL}/logout`,
  saveProject: `${SERVICE_URL}/project/home/saveProject/`,
  deleteProject: `${SERVICE_URL}/project/home/deleteProject/`,
  validateProjectForm: `${SERVICE_URL}/project/home/inputDataValidation/`,
  bookmarkProject: `${SERVICE_URL}/project/home/bookmarkProject/`,
  getProjectDetail: `${SERVICE_URL}/project/home/getProject/`,
  getAssumptions: `${SERVICE_URL}/project/home/textinput/`,
  assumptionAttachments:`${SERVICE_URL}/project/home/attachment/`,
  logicAppUrl:'https://prod-00.eastus.logic.azure.com:443/workflows/2fac5721b6bd481390aed51ccb960dde/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=scRHpD3gc4ilIgo8pOkXZc0Q59xCFVN6NQAnTyfN_Qc',
  projectStatuslogicAppUrl:'https://prod-00.eastus.logic.azure.com:443/workflows/1f01ca4f81b041638d6e382d1d139e62/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Jl0PNd-pnRR65EWy4m76KCOKXfcHlvoQX2wqcSDj15A',
  volumeAssumptionSummary: `${SERVICE_URL}/volume/input/calculateVolumeAssumptions/`,
  volumeAssumptionGet: `${SERVICE_URL}/volume/input/fetchDetails/`,
  volumeAssumptionSave: `${SERVICE_URL}/volume/input/saveInputs/`,
  volumeAssumptionDeleteTab: `${SERVICE_URL}/volume/input/deleteItem/`,
  volumeAssumptionAddTab: `${SERVICE_URL}/volume/input/addItem/`,

  growthInputGet: `${SERVICE_URL}/growth/input/getGrowthInputs/`,
  growthInputGet2: `${SERVICE_URL}/depreciation/input/getBookDepreciationMac/`,
  growthInputSave: `${SERVICE_URL}/growth/input/saveGrowthInputs/`,
  growthInputCalculate: `${SERVICE_URL}/growth/input/calculateGrowthKpis/`,

  investInputGet: `${SERVICE_URL}/investment/input/getInvestmentInputs/`,
  investInputCalculate: `${SERVICE_URL}/investment/input/calculateInvestmentKpis/`,
  investInputSave: `${SERVICE_URL}/investment/input/saveInvestmentInputs/`,

  timingExpenditureInputGet: `${SERVICE_URL}/expenditure/input/getExpenditureInputs/`,
  timingExpenditureInputAdd: `${SERVICE_URL}/expenditure/input/addDescriptionItem/`,
  timingExpenditureInputDelete: `${SERVICE_URL}/expenditure/input/deleteDescriptionItem/`,
  timingExpenditureInputSave: `${SERVICE_URL}/expenditure/input/saveExpenditureInputs/`,

  benefitsInputGet: `${SERVICE_URL}/benefits/input/getBenefitsInputs/`,
  benefitsInputAdd: `${SERVICE_URL}/benefits/input/addBenefitsItem/`,
  benefitsInputDelete: `${SERVICE_URL}/benefits/input/deleteBenefitsItem/`,
  benefitsInputSave: `${SERVICE_URL}/benefits/input/saveBenefitsInputs/`,

  kbpsInputGet: `${SERVICE_URL}/kbps/input/getKBPsInputs/`,
  kbpsInputSave: `${SERVICE_URL}/kbps/input/saveKBPsInputs/`,

  capitalInputGet: `${SERVICE_URL}/capital/input/getCapitalInputs/`,
  capitalSuperSave: `${SERVICE_URL}/project/home/projectSuperInputs/`,
  capitalInputSave: `${SERVICE_URL}/capital/input/saveCapitalInputs/`,

  fatrInputGet: `${SERVICE_URL}/fatr/input/getFATRInputs/`,
  fatrInputSave: `${SERVICE_URL}/fatr/input/saveFATRInputs/`,

  depreciationGet: `${SERVICE_URL}/depreciation/input/getDepreciationInputs/`,
  depreciationSave: `${SERVICE_URL}/depreciation/input/saveDepreciationInputs/`,

  resultValidate: `${SERVICE_URL}/results/getResults/`,
  sourceEarning: `${SERVICE_URL}/source_of_earnings/getSourceOfEarnings/`,

  summaryOverview: `${SERVICE_URL}/summary/getProjectSummary/`,
  summarySaveOverview: `${SERVICE_URL}/summary/saveProjectSummary/`,
  summaryRecommend: `${SERVICE_URL}/summary/getSummary/`,
  epDetailRecommend: `${SERVICE_URL}/ep_detailed/getEPdata/`,
  epProfitRecommend: `${SERVICE_URL}/economic_profit/getEconomicProfit/`,
  plcalculate: `${SERVICE_URL}/depreciation/input/calculatePLKpis/`,
  plbacalculate: `${SERVICE_URL}/pl_before_after/calculatePLDetails/`,
  plbahighLevel: `${SERVICE_URL}/pl_before_after/highlevelfinancials/`,
  duplicateProject: `${SERVICE_URL}/project/home/duplicate/`,

  superUserGet: `${SERVICE_URL}/super_user_input/getSuperUserInput`,
  superUserSave: `${SERVICE_URL}/super_user_input/saveSuperUserInput`,
  superLocalCurrencySave: `${SERVICE_URL}/project/home/saveCurrencyMapping/`,
  superLocalCurrencyGet: `${SERVICE_URL}/project/home/getCurrencyMapping/`,
  superSiteMappingSave: `${SERVICE_URL}/project/home/saveSiteMapping/`,
  superSiteMappingGet: `${SERVICE_URL}/project/home/getSiteMapping/`,
  superPlBASave: `${SERVICE_URL}/pl_before_after/savePLDetails/`,
  superPlBAGet: `${SERVICE_URL}/pl_before_after/getPLDetails/`,

  irrNPVGet: `${SERVICE_URL}/irr_npv/getIrrNpv/`,

  sensitivitesCalculateVol: `${SERVICE_URL}/sensitivities/calculateFaaVolIRR/`,
  sensitivitesCalculateMac: `${SERVICE_URL}/sensitivities/calculateFaaMacIRR/`,
  sensitivitesCalculateVolEP: `${SERVICE_URL}/sensitivities/calculateFaaVolEP/`,
  sensitivitesCalculateMacEP: `${SERVICE_URL}/sensitivities/calculateFaaMacEP/`,
  sensitivitesCalculateEarnings: `${SERVICE_URL}/sensitivities/calculateFaaEarningsIRR/`,
  sensitivitesCalculateEarningsEP: `${SERVICE_URL}/sensitivities/calculateFaaEarningsEP/`,
  sensitivitesgetDate: `${SERVICE_URL}/sensitivities/getData/`,
  sensitivitessaveData: `${SERVICE_URL}/sensitivities/saveData/`,

  savingsSimulationSave: `${SERVICE_URL}/simulation/saveInputs/`,
  savingsSimulationUpdate: `${SERVICE_URL}/simulation/updateInputs/`,
  savingsSimulationGet: `${SERVICE_URL}/simulation/getInputs/`,
  savingsSimulationCalculate: `${SERVICE_URL}/simulation/calculateSavingsSimulation/`,

  growthSimulationSave: `${SERVICE_URL}/simulation/savegrowth/`,
  growthSimulationGet: `${SERVICE_URL}/simulation/getgrowth/`,
  carbonSourceData: `${SERVICE_URL}/super_user_input/saveSourceData`,
  carbonSourceDataGet: `${SERVICE_URL}/super_user_input/getSourceData`,

  // Carbon Foot Print Endpoints
  carbonFootPrintSourceDropdownGet: `${SERVICE_URL}/carbon_footprint/getSourceDatadropdown`,
  carbonFootPrintFetchDataPost: `${SERVICE_URL}/carbon_footprint/getCarbonExistingDetails`,
  carbonFootPrintSaveDataPost: `${SERVICE_URL}/carbon_footprint/saveCarboninput`,
  carbonSensitivityDataGet : `${SERVICE_URL}/carbon_footprint/getCarbonSenstivityExistingDetails`,
  carbonSensitivityDataPost : `${SERVICE_URL}/carbon_footprint/saveCarbonSensitivityinput`,
  carbonIRREPCalculate: `${SERVICE_URL}/carbon_footprint/getCarbonIRREP`,
   //common apis
  recalculateData: `${SERVICE_URL}/common/recalculateData/`,
};
export const DateFormat = "MM/DD/YYYY";
export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
